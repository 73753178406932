
export default {
  name: "PricingSection",
  data() {
    return {
      pricingPlans: [
        {
          title: "usage",
          price: " 0,1",
          subtitle: "pro Abfrage",
        },
        {
          title: "basic",
          price: " 10",
          subtitle: "monatlich",
        },
        {
          title: "premium",
          price: " 25",
          subtitle: "monatlich",
        },
        // fügen Sie hier weitere Pläne hinzu
      ],
    };
  },
};
