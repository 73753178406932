import { createApp } from "vue";
import App from "./App.vue";
import "@/assets/global.css";
import "bootstrap/dist/css/bootstrap.css";
import "jquery";
import "bootstrap/dist/js/bootstrap.js";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faRobot,
  faLightbulb,
  faUsers,
  faClock,
  faBrain,
  faHandshakeAngle,
  faEuroSign,
  faHeart,
} from "@fortawesome/free-solid-svg-icons";
import {
  faInstagram,
  faFacebook,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import AOS from "aos";
import "aos/dist/aos.css";

AOS.init();

library.add(
  faRobot,
  faLightbulb,
  faUsers,
  faClock,
  faBrain,
  faHandshakeAngle,
  faEuroSign,
  faHeart,
  faInstagram,
  faFacebook,
  faLinkedin
); // Add the icons you want to use

const app = createApp(App); // Create the Vue app

app.component("font-awesome-icon", FontAwesomeIcon); // Register the FontAwesomeIcon component globally

app.mount("#app"); // Mount the Vue app
