
import Header from "./components/TheHeader.vue";
import HeroSection from "./components/HeroSection.vue";
import AboutSection from "./components/AboutSection.vue";
import PricingSection from "./components/PricingSection.vue";
import Footer from "./components/TheFooter.vue";
import Testimonial from "./components/TestimonialSection.vue";
import Contact from "./components/ContactSection.vue";

export default {
  name: "App",
  components: {
    Header,
    HeroSection,
    AboutSection,
    PricingSection,
    Footer,
    Testimonial,
    Contact,
  },
};
