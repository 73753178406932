
export default {
  name: "TheFooter",
  data() {
    return {
      socialMediaLinks: {
        // Fügen Sie Ihre Social Media Links hier hinzu, z.B.
        Facebook: {
          link: "https://www.facebook.com/keravonos",
          icon: "fab fa-facebook",
        },
        Instagram: {
          link: "https://www.instagram.com/keravonos.de/",
          icon: "fab fa-instagram",
        },
        LinkedIn: {
          link: "https://www.linkedin.com/company/keravonos",
          icon: "fab fa-linkedin",
        },

        // usw.
      },
    };
  },
};
