import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "app" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_HeroSection = _resolveComponent("HeroSection")!
  const _component_AboutSection = _resolveComponent("AboutSection")!
  const _component_Testimonial = _resolveComponent("Testimonial")!
  const _component_PricingSection = _resolveComponent("PricingSection")!
  const _component_Contact = _resolveComponent("Contact")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Header),
    _createVNode(_component_HeroSection),
    _createVNode(_component_AboutSection),
    _createVNode(_component_Testimonial),
    _createVNode(_component_PricingSection),
    _createVNode(_component_Contact),
    _createVNode(_component_Footer)
  ]))
}