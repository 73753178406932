
import axios from "axios";
import { ref } from "vue";

export default {
  // data() {
  //   return { response_message: "" }
  // },
  setup() {
    const response_message = ref("")
    const buttonDisable = ref(false)
    const form = ref({
      name: "",
      email: "",
      message: "",
    });

    async function submitForm() {
      buttonDisable.value = true
      try {
        // Daten aus dem Formular abrufen
        const { name, email, message } = form.value;
        const formDataRecipient = new FormData();
        formDataRecipient.append('recipients', JSON.stringify({ email, name }))
        formDataRecipient.append('sender', 'kontakt@keravonos.com')
        formDataRecipient.append('sender_name', 'OKR-Checker')
        formDataRecipient.append('subject', "Kontaktanfrage OKR-Checker")
        formDataRecipient.append('text', message)
        formDataRecipient.append('template_id', 'yzkq340pp0xld796')
        formDataRecipient.append('html', `
        <h1>Vielen Dank für deine Nachricht, ${name}</h1>
        <p>Wir werden uns kurzfristig bei dir melden! </p>
        <p>Folgendes stand in der Kontaktanfrage: </p>
        <p>${message}</p>
        <p>Beste Grüße</p>
        <p>Dein OKR-Checker Team</p>
        `)

        const formDataHost = new FormData();
        formDataHost.append('recipients', JSON.stringify({ email: 'kontakt@keravonos.com', name:'Keravonos Team' }))
        formDataHost.append('sender', 'kontakt@keravonos.com')
        formDataHost.append('sender_name', 'OKR-Checker')
        formDataHost.append('subject', "Kontaktanfrage OKR-Checker")
        formDataHost.append('text', message)
        formDataHost.append('html', `
        <h1>Kontaktformular wurde ausgefüllt von ${name}</h1>
        <p>Folgendes stand in der Kontaktanfrage: </p>
        <p>${message}</p>
        <p>E-Mail Adresse: ${email}</p>
        <p>Beste Grüße</p>
        <p>Dein OKR-Checker Team</p>
        `)
  

        // axios.defaults.withCredentials = true;

        const apiToken = '825f447d-be86-4480-9225-21c238569954';
        
        // Validierung der Eingabe (optional)

        // E-Mail-Versand-Anfrage erstellen
        const response_recipient = await axios.post("http://localhost:5000/send_email",
        formDataRecipient,
        {
        headers: {
          "Api-Token": `${apiToken}`,
          "Content-Type": "multipart/form-data",
        }
          });

        const response_host = await axios.post("http://localhost:5000/send_email",
        formDataHost,
        {
        headers: {
          "Api-Token": `${apiToken}`,
          "Content-Type": "multipart/form-data",
        }
        });

        // Überprüfen Sie die Antwort und führen Sie entsprechende Aktionen durch
        if (response_recipient.status === 202) {
          response_message.value = "E-Mail erfolgreich versendet"
          // Erfolgsmeldung anzeigen oder andere Aktionen ausführen
        } else {
          response_message.value = "Fehler beim Versenden der E-Mail"
          // Fehlermeldung anzeigen oder andere Aktionen ausführen
        }

        // Formular zurücksetzen
        form.value.name = "";
        form.value.email = "";
        form.value.message = "";
        buttonDisable.value = false
      } catch (error) {
        // Fehlerbehandlung
        console.error("Fehler beim Versenden der E-Mail:", error);
        // Fehlermeldung anzeigen oder andere Aktionen ausführen
      }
    }

    return {
      buttonDisable,
      response_message,
      form,
      submitForm,
    };
  },
};
