
export default {
  data() {
    return {
      testimonials: [
        {
          quote:
            "Der OKR-Checker ist wie ein AI-Sparringspartner für Ihr Team, der mit unschätzbarem Input hilft, Ziele zu schärfen und zu verbessern - er revolutioniert die Art und Weise, wie wir gemeinsam unsere Ambitionen formulieren.",
          author: "OpenAI",
          position: "ChatGPT 4.0 Turbo",
          image: require("@/assets/john_doe.png"),
        },
        // {
        //   quote:
        //     "Ich liebe diese App! Endlich habe ich einen Sparingspartner, der mich bei der Formulierung meiner Ziele unterstützt! Ich konnte meine Ziele so viel besser formulieren und bin nun viel motivierter, diese auch zu erreichen!",
        //   author: "Jannike Hansen",
        //   position: "Teamleiterin  bei Großhandel Hinze",
        //   image: require("@/assets/jane_smith.avif"),
        // },
        // fügen Sie weitere Testimonials hinzu...
      ],
    };
  },
};
