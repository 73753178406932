
export default {
  name: "AboutSection",
  data() {
    return {
      usps: [
        {
          title: "AI-basierte Qualitätssicherung",
          description:
            "Die Integration von OpenAI ermöglicht eine künstliche Intelligenz-basierte Überprüfung von OKRs. Dies gewährleistet eine durchgängige und objektive Qualitätssicherung und liefert Einblicke, die manuell möglicherweise übersehen werden könnten.",
          icon: ["fas", "robot"],
        },
        {
          title: "Individuelle Vorschläge für Key Results",
          description:
            "Der OKR-Checker bietet nicht nur eine Überprüfung, sondern liefert auch Vorschläge zur Verbesserung der Key Results. Dies kann die Qualität der Zielsetzungen erheblich verbessern und die Zielerreichung erleichtern.",
          icon: ["fas", "lightbulb"],
        },
        {
          title: "Förderung gemeinsamer Zielformulierungen",
          description:
            "Der OKR-Checker hilft Teams dabei, ihre OKRs besser zu formulieren und aufeinander abzustimmen. Dies kann die Zusammenarbeit verbessern, die Zielerreichung erleichtern und die gemeinsame Ausrichtung stärken.",
          icon: ["fas", "handshake-angle"],
        },
        {
          title: "Zeitersparnis und Effizienz",
          description:
            "Durch die automatisierte Überprüfung und Verbesserung der OKRs spart der OKR-Checker Zeit, die sonst in manuelle Überprüfungen und Diskussionen investiert werden müsste. Dies ermöglicht den Teams, sich auf die Umsetzung ihrer OKRs zu konzentrieren.",
          icon: ["fas", "clock"],
        },
        {
          title: "Lernende AI-Technologie",
          description:
            "Mit der Zeit lernt der OKR-Checker dazu und wird immer besser darin, OKRs zu analysieren und Verbesserungsvorschläge zu machen. Dies bedeutet, dass die Qualität der OKRs und die Effizienz des Prozesses kontinuierlich verbessert werden.",
          icon: ["fas", "brain"],
        },
      ],
    };
  },
};
